<template>
  <div class="box">
    <!-- <div class="box-top">
      <strong>预言机服务</strong>
    </div> -->
    <div class="crosschain-body">
      <div class="img">
        <img
          src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
          class="slot-img"
        />
      </div>
      <div>开启预言机服务，可以完成与其他区块链之间的数据交互</div>
      <div style="display: flex">
        <Button
          @click="info()"
          type="mini-blue"
          text="了解详情"
          style="margin-right: 20px"
        />
        <Button @click="apply()" type="white" text="申请服务" />
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@/components/common/Index';

export default {
  components: {
    Button,
  },
  methods: {
    info: () => {
      console.log('info');
    },
    apply() {
      this.$router
        .push({
          name: 'Form',
          query: {
            id: this.$route.matched.slice(-1)[0].props.default.formId,
            groupName: this.$route.matched.slice(-1)[0].props.default.title,
          },
        })
        .catch((e) => e);
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  text-align: left;
}
.crosschain-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box-top {
  display: flex;
  margin-top: 16px;
  margin-left: 30px;
}
.img {
  width: 800px;
}
.slot-img {
  width: 100%;
  height: 372px;
}
</style>
